import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/scholarship/types', config);
}

const get = async (code) => {
    return FinanceApi.get('/scholarship/types/' + code);
}

const store = async (data) => {
    if (data.id) {
        return FinanceApi.put('/scholarship/types/' + data.id, data);
    }
    return FinanceApi.post('/scholarship/types', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/scholarship/types/' + id, formData);
}

const deleteScholar = async (id) => {
    return FinanceApi.delete('/scholarship/types/' + id);
}

export default {
    getAll,
    get,
    store,
    update,
    deleteScholar
}
