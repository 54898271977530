<template>
    <div>
        <div class="row" v-if="!showForm">
            <div class="col-12" v-if="checkPermission('customernote_store')">
                <b-button variant="outline-secondary secondary-border-light"
                          class="mr-2" @click="showForm=true">
					        <span class="d-flex">
						        <i class="ri-add-line mr-1"></i>
					            {{ $t('new').toUpper() }}
					        </span>
                </b-button>
            </div>
            <div class="col-12">
                <b-table
                    v-if="data && data.length>0"
                    bordered
                    striped
                    responsive
                    :items="data"
                    :fields="fields"
                    show-empty
                    class="mb-5 table-dropdown no-scrollbar border rounded">
                    <template #cell(dropdownTable)="row">
                        <div class="d-flex">

                            <b-button class="text-danger" variant="link" size="xs"
                                      @click="deleteNote(row.item.id)">
                                {{ $t('delete') | toUpperCase }}
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </div>

        </div>
        <div class="row mb-5 align-items-center" v-if="showForm">
            <div class="col-12">
                <ValidationObserver ref="form">
                    <div class="row">
                        <div class="col-12">
                            <ValidationProvider name="note" rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('note')">
                                    <b-form-textarea v-model="form.note">
                                    </b-form-textarea>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
            <div class="col-12 mt-3 d-flex">
                <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                    {{ $t('save').toUpper() }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
//Service
import CustomerNote from "@/services/CustomerNoteService";
//Component
import qs from "qs";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: {
        customer_id: {
            default: null
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'note',
                    label: this.toUpperCase('note')
                },
                {
                    key: 'created_at',
                    label: this.toUpperCase('created_at'),
                    formatFn: (value) => {
                        return value ? this.toLocaleDate(value) : ''
                    },
                },
                {key: 'dropdownTable', label: '#'}
            ],
            form: {},
            data: null,
            showForm:false
        }
    },
    methods: {
        loadData() {
            const config = {
                params: {
                    filter: {customer_id: this.customer_id},
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            CustomerNote.getAll(config).then(response => {
                this.data = response.data.data;
            }).catch(e => {
                this.showErrors(e);
            });
        },

        async store() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.form.customer_id = this.customer_id;
                CustomerNote.store(this.form)
                    .then(response => {
                        this.loadData();
                        this.form = {};
                        this.toast(response.data);
                        this.showForm=false;
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            }
        },
        deleteNote(id) {
            if (id > 0) {
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            CustomerNote.del(id)
                                .then(response => {
                                    this.toast(response.data);
                                    this.loadData();
                                })
                                .catch(e => {
                                    this.showErrors(e);
                                })
                        }
                    })

            }
        }
    },
    mounted() {
        this.loadData();
    }
}
</script>
