import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/payment/approval/exceptions', config);
}

const get = async (code) => {
    return FinanceApi.get('/payment/approval/exceptions/' + code);
}

const store = async (data) => {
    if (data.id) {
        return FinanceApi.put('/payment/approval/exceptions/' + data.id, data);
    }
    return FinanceApi.post('/payment/approval/exceptions', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/payment/approval/exceptions/' + id, formData);
}

const del = async (id) => {
    return FinanceApi.delete('/payment/approval/exceptions/' + id);
}

export default {
    getAll,
    get,
    store,
    update,
    del
}
