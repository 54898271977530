import FinanceApi from '@/services/FinanceApi';

const storeDocument = async (formData) => {
    return FinanceApi.post('/documents', formData);
}

const deleteDocumentByUuid = async (uuid) => {
    return FinanceApi.delete('/documents/media/' + uuid);
}

const downloadDocument = async (uuid) => {
    return FinanceApi.get('/documents/download/' + uuid, {responseType: 'arraybuffer'});
}

export default {
    storeDocument,
    deleteDocumentByUuid,
    downloadDocument
}
